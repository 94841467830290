.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &.page {
    height: 100vh;
    width: 100vw;
  }
}

.loading {
  animation: spin 0.5s linear infinite;
  border: 5px solid var(--color-grey-01);
  border-radius: 50%;
  border-top: 5px solid var(--color-primary);
  margin: 0;
  min-height: 20px;
  min-width: 20px;

  &.small {
    height: 20px;
    width: 20px;
  }

  &.normal {
    height: 50px;
    width: 50px;
  }

  &.large {
    height: 100px;
    width: 100px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
